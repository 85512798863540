import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
import Lottie from "react-lottie-player";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h2>{`What is Legion?`}</h2>
    <p>{`Legion is a design system created as a comprehensive solution to address the challenges faced by Telkom Indonesia’s digital product team, striving for consistency, quality, and an exceptional user experience in a rapidly expanding landscape of brands and products. It serves as the definitive source of truth during the product development process, fostering productivity and collaboration, ultimately enabling us to deliver our finest digital products efficiently and effectively.`}</p>
    <div {...{
      "className": "about-us-card"
    }}>{`
  `}<h2 parentName="div">{`Boost Efficiency with Legion`}</h2>{`
  `}<div parentName="div" {...{
        "className": "about-us-card-detail"
      }}>{`
    `}<img parentName="div" {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/aboutus/boost-about-us.svg",
          "alt": "boost efficiency"
        }}></img>{`
    `}<p parentName="div">{`
      Legion as Multi-Brand Design System helps us work together to create a more delightful and consistent experience
      across the product. Through an atomic design, approach to methodically craft a single source of truth from all UI
      components and patterns, paired with documentation to help clarify and focus our efforts on building the best user
      experience.
    `}</p>{`
  `}</div>
    </div>
    <div className="divi" />
    <h2>{`We’re different and powerful`}</h2>
    <p>{`We have thousands of User Interface components, tons of colour variations, a complete set of icons, multiple font styles, useful grids, patterns, and many other reusable assets to help us make cross-brand UI design and development as fast as possible while maintaining high quality and accessibility for more than 10 digital product brands.`}</p>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/aboutus/about-us-products.svg",
      "alt": "products legion"
    }}></img>
    <p>{`And many more…`}</p>
    <div className="divi" />
    <div {...{
      "className": "about-us-card"
    }}>{`
  `}<h2 parentName="div">{`Save your time and build more`}</h2>{`
  `}<div parentName="div" {...{
        "className": "about-us-card-detail"
      }}>{`
    `}<p parentName="div">{`
      Our goal's to empower designers and developers to iterate together using the same components or patterns in the
      same medium. As a result, we believe that we can increase efficiency and consistency, and scalability when
      building our products.
    `}</p>{`
    `}<img parentName="div" {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/aboutus/about-us-save-time.svg",
          "alt": "boost efficiency"
        }}></img>{`
  `}</div>
    </div>
    <div className="divi" />
    <h2>{`Our advanced features`}</h2>
    <p>{`Experience accessibility, customization, and efficiency in one package. Enhance inclusivity, customize components with ease, and optimize your workflow.`}</p>
    <div className="about-us-features">
  <div className="grey-card">
    <h3>Accessible</h3>
    <p>
      Follow the specifications of accessibility level, so you can make inclusive digital products to enhance the user
      experience standard of digital products
    </p>
  </div>
  <div className="grey-card">
    <h3>Customizeable</h3>
    <p>
      Every product can customize components and pattern styles according to their needs in the same system with easy
      maintenance
    </p>
  </div>
  <div className="grey-card">
    <h3>Efficiency</h3>
    <p>
      Ensures the maximum quality of time spent on code and design, with minimal wasted energy, to achieve the same
      results
    </p>
  </div>
    </div>
    <div className="divi" />
    <div {...{
      "className": "about-us-one-system"
    }}>{`
  `}<img parentName="div" {...{
        "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/aboutus/about-us-one-system.svg",
        "alt": "one system"
      }}></img>{`
  `}<div parentName="div" {...{
        "className": "about-us-one-system-detail"
      }}>{`
    `}<h2 parentName="div">{`One system for all`}</h2>{`
    `}<div parentName="div" {...{
          "className": "grey-card",
          "style": {
            "marginBottom": "24px"
          }
        }}>{`
      `}<h3 parentName="div">{`Designers`}</h3>{`
      `}<p parentName="div">{`
        Get more time to make a delightful product experience by familiarizing themselves with the style, design
        documentation, or guideline to define and build the interfaces using our design library.
      `}</p>{`
    `}</div>{`
    `}<div parentName="div" {...{
          "className": "grey-card",
          "style": {
            "marginBottom": "24px"
          }
        }}>{`
      `}<h3 parentName="div">{`Developers`}</h3>{`
      `}<p parentName="div">{`
        If something is unclear on the designer mockups, we will be your guide if you have questions about the detailed
        implementation of a particular component or source code pattern.
      `}</p>{`
    `}</div>{`
    `}<div parentName="div" {...{
          "className": "grey-card"
        }}>{`
      `}<h3 parentName="div">{`Stakeholders`}</h3>{`
      `}<p parentName="div">{`
        You can use this design system as the resource for proposing ideas within an existing component base to increase
        speed and agility. Save time and grow faster by knowing the product design and development
      `}</p>{`
    `}</div>{`
  `}</div>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      